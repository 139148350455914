import React from 'react'

import {
  Help,
  Link,
  List,
  ListItem,
  Paragraph,
  Required
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { Link as GatsbyLink } from '../../components/Link'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Required"
    components={[{ component: Required }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Help>
        <Paragraph>
          <Code>Required</Code>-komponentti on tarkoitettu erikoistilanteisiin.
          Pakollisesta lomakekentästä ilmaiseva ominaisuus löytyy{' '}
          lomakekomponenteista sisäänrakennettuna (<Code>required</Code>-prop).
          Lomakekomponenttien oma prop on aina ensisijainen ja suositeltu tapa.
        </Paragraph>
        <Paragraph>
          Myös{' '}
          <Code>
            <GatsbyLink page="Label" />
          </Code>{' '}
          -komponentti sisältää sisäänrakennetun <Code>required</Code>-propin.
        </Paragraph>
        <Paragraph noMargin>
          Jos jostakin syystä on käytettävä <Code>Required</Code>-komponenttia,
          on huolehdittava, myös input-elementtiin lisätään{' '}
          <Code>required</Code>-attribuutti. Myös label tulee liittää
          input-elementtiin. Tarkempi tekninen ohjeistus löytyy{' '}
          <Code>Label</Code>-komponentin{' '}
          <Link href="/lomakkeet/label/#pakollisuus">
            pakollisuus-esimerkistä
          </Link>
          ).
        </Paragraph>
      </Help>
    </Section>
    <Section>
      <Playground>
        <Required />
      </Playground>
      <Playground format="html">
        <span
          aria-hidden="true"
          className="required"
        />
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että <Code>Required</Code>ia käytettäessä myös
          input-elementti on merkitty <Code>required</Code>-attribuutilla.
        </ListItem>
        <ListItem>
          <Code>Required</Code>ia käytetään lomakekentän labelin yhteydessä.
          Tarkista, että myös label on liitetty input-elementtiin. Varmista,
          että käyttäjälle on annettu riittävä ohjeistus sublabelissa.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
